import React from 'react'

function PageNotFound() {
  return (
    <>
      <h1>We Are Coming Soon Here.....</h1>
    </>
  )
}

export default PageNotFound;
