import React from 'react';
import Fade from 'react-reveal/Fade';
import './css/platforms.css';
import MarqueeComponent from './MarqueeComponent';
import platform1 from './images/Platforms/platform1.webp';
import platform2 from './images/Platforms/platform2.webp';
import platform3 from './images/Platforms/platform3.webp';
import platform4 from './images/Platforms/platform4.webp';
import platform5 from './images/Platforms/platform5.webp';
import platform6 from './images/Platforms/platform6.webp';
import platform7 from './images/Platforms/platform7.webp';
import platform8 from './images/Platforms/platform8.webp';
import platform9 from './images/Platforms/platform9.webp';
import platform10 from './images/Platforms/platform10.jpg';

function Platforms() {
  return (
    <>
    <Fade bottom>
      <div className='platforms-div'>
        <h3>Platforms</h3>
        <h2>Platforms We Work With</h2>
        <MarqueeComponent/>
      </div> 
 </Fade>


 {/* platforms Marquee */}
 <div className='platforms-outter-div'>
        <marquee behavior='scroll' scrollamount='10'>

        <img src={platform1} alt='platform1' /> 
        <img src={platform2} alt='platform2' /> 
        <img src={platform3} alt='platform3' /> 
        <img src={platform4} alt='platform4' /> 
        <img src={platform5} alt='platform5' /> 
        <img src={platform6} alt='platform6' /> 
        <img src={platform7} alt='platform7' /> 
        <img src={platform8} alt='platform8' /> 
        <img src={platform9} alt='platform9' /> 
        <img src={platform10} alt='platform10' /> 
         
        </marquee>
    </div>
      
    </>
  )
}

export default Platforms
