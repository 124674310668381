import React from 'react';
import './css/chooseus.css';
import MarqueeComponent from './MarqueeComponent';
import ChooseImg from './images/choose.jpg'
import Fade from 'react-reveal/Fade'

function ChooseUs() {
  return (
    <>


    <div className='choose-us-div'>
        <Fade bottom>
        <h3>Why Choose Us</h3>
        <h2>We Are Here to Grow </h2>
        <h2>Your Business Exponentially</h2>
        <MarqueeComponent/>
        </Fade>
    </div> 
    
{/* Choose Info Left */}
    <div className='choose-main-div'>
        <div className='choose-info-left'>
        <Fade bottom>
            <div className='choose-info'>
            <i class="fa-solid fa-boxes-stacked boxes"></i>
            <h3>Best In Industry</h3>
            <p>We are best in Industry.</p>
            <i class="fa-solid fa-award award"></i>
           <h3> Award Winning </h3>
           <p>Award Winning IT Company.</p>
            </div>
            </Fade>
        </div>

{/* Choose Center Image */}
<Fade bottom>
        <div className='choose-image'>
             <img src={ChooseImg} className='img-fluid' alt='chooseImage'/>
        </div>
</Fade>

{/* Choose Info Right */}
<Fade bottom>
        <div className='choose-info-right'>'
        <div className='choose-info'>
            <i class="fa-solid fa-users-gear"></i>
            <h3>Professional Staff</h3>
            <p>Team of Professional Staff.</p>
            <i class="fa-solid fa-phone"></i>
           <h3> 24/7 Support </h3>
           <p>24/7 Support for Customers.</p>
            </div>
        </div>
  </Fade>
    </div>
    </>
  )
}

export default ChooseUs;
