import React, { useEffect } from 'react';
import './css/home.css';
import Counter from './Counter';
import About from './About';
import ChooseUs from './ChooseUs';
import Services from './Services';
import Pricing from './Pricing';
import Quote from './Quote';
import BrandsMarquee from './BrandsMarquee';
import Testimonial from './Testimonial';
import Team from './Team';
import GotoTop from './GotoTop';
import WhatsappBtn from './WhatsappBtn';
import Platforms from './Platforms';
import ImageSlider from './ImageSlider';
import WhyWe from './WhyWe';
import ContactBanner from './ContactBanner';
// import MarqueeComponent from './MarqueeComponent';


function Home() {

   useEffect(()=>{
    window.scrollTo(0,0);
   }, [])


  return (
    <div>
    

{/* Image Slider */}
<ImageSlider/>

{/* Home Page Counter  */}
<Counter/>

{/* Home Page About */}
<About/>


{/* Home Page Chooseus */}
<ChooseUs/>

{/* Home Page Services */}
<Services/>


{/* Home Platforms */}
<Platforms/>

{/* Home Page Pricing */}
<Pricing/>


{/* Home Whywe Div */}
<WhyWe/>

{/*  Home Page Quote*/}
<Quote/>


{/* Home Testimonial Div */}
<Testimonial/>

{/* Home Contact Banner */}
<ContactBanner/>

{/* Home Page Team Div */}
<Team/>

{/* Home Page Brands */}
<BrandsMarquee/>


<GotoTop/>
<WhatsappBtn/>

</div>

  
  )
}

export default Home;
