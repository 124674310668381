import React, { useState } from 'react';
import './css/quote.css';
import MarqueeComponent from './MarqueeComponent';
import Fade from 'react-reveal/Fade';

function Quote() {
  const [data, setData] = useState({
    name: '',
    mobile: '',
    email: '',
    message: ''
  });

  const { name, mobile, email, message } = data;

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        'https://v1.nocodeapi.com/wearebright/google_sheets/WjCbGqTdrsUddgHI?tabId=Sheet1',
        {
          method: 'POST',
          headers: {
            'Content-type': 'application/json'
          },
          body: JSON.stringify([[name, mobile, email, message, new Date().toLocaleString()]])
        }
      );
      await response.json();
      window.alert(`Thankyou ${name} for contact us.We will reach you soon:)`)
      setData({ name: '', mobile: '', email: '', message: '' });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className='quote-main-div'>
        {/* Quote CTA Div */}
        <div className='quote-cta'>
          <div className='cta-text'>
            <Fade bottom>
              <h3>Get in Touch</h3>
              <h2>Need A Free Consultation? Please Feel</h2>
              <h2>Free to Contact Us</h2>
              <MarqueeComponent />
            </Fade>
          </div>

          <Fade bottom>
            <div className='quote-icons'>
              <i className='fa-solid fa-reply quote-reply'></i>Reply within 24 hours
              <i className='fa-solid fa-phone quote-call'></i>24 hrs telephone support
            </div>
          </Fade>

          {/* make like as a About CTA */}
          <div className='about-cta'>
            <Fade bottom>
              <div className='about-call'>
                <i className='fa-solid fa-phone'></i>
              </div>
              <div className='about-call'>
                <span>Call to ask any question</span>
                <br />
                <span>+91 94599 07845</span>
              </div>
            </Fade>
          </div>
        </div>

        {/* Quote form div */}
        <Fade bottom>
          <div className='quote-form-div'>
            <form autoComplete="off" className='quote-form' onSubmit={handleSubmit}>
              <input type='text' placeholder='Your Name' name='name' value={name} onChange={handleChange}   required/>

              <input type='text' placeholder='Your Mobile' name='mobile' value={mobile} onChange={handleChange} required/>

              <input type='email' placeholder='Your Email' name='email' value={email} onChange={handleChange} required/>

              <textarea
                placeholder='Message'
                name='message'
                cols='20'
                value={message}
                onChange={handleChange}
                required
              ></textarea>

              <button className='quote-submit-btn' type='submit'>
                Request A Callback
              </button>
            </form>
          </div>
        </Fade>
      </div>
    </>
  );
}

export default Quote;
