import React from 'react';
import './css/mission.css';

function OurMission() {
  return (
    <>
      <div class="about-mission">
  <h2>Our Mission</h2>
  <p>
    At Brightnext Technology, our mission is to provide high-quality digital marketing solutions that help our clients achieve their business objectives. We believe in building long-lasting relationships with our clients by understanding their unique needs and delivering customized solutions that exceed their expectations. Our team of experts stays up-to-date with the latest trends and technologies to ensure that our clients receive the best possible service.
  </p>
</div>
    </>
  )
}

export default OurMission
