import React, { useEffect } from 'react';
import About from './About'
import './css/aboutpage.css';
import OurMission from './OurMission';

function AboutUsPage() {
    useEffect(()=>{
        window.scrollTo(50,50);
       }, [])
  return (
    <>
      <div className="about-banner-head">
      <h1 className='banner-text'>Boost Your Business with the Best Digital Marketing Agency in Himachal - 6 Years of Experience!</h1>
      </div>


      <div class="about-para-section">
  <h2>About Brightnext Technology</h2>
  <h4>Leading No.1 Best Online Marketing Company in Mohali and Himachal Pradesh</h4>
  <p>
    <span class='qstn'>Are you searching for the best digital marketing agency with 6 years of experience in providing top-notch IT solutions in Mohali and Himachal?</span>
    <br />

    Look no further than our team of experts! As the top-rated digital marketing agency in both regions, we offer a comprehensive range of services tailored to meet your specific needs. With our in-depth knowledge of the latest trends and techniques in digital marketing, we guarantee to take your business to the next level. From website development to SEO services, social media marketing, and more, we have the expertise to help you achieve your goals. Contact us today to learn more about how we can help your business succeed!
  </p>
</div>


 {/* Mission Section */}
 <OurMission/>


      {/* about component of home page */}
      <About/>
    </>
  )
}

export default AboutUsPage;
