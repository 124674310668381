import React, { useEffect } from 'react';
import './css/digitalmarketing.css';
import Fade from 'react-reveal/Fade';
import ContactBanner from '../ContactBanner';

function DigitalMarketingPage() {
  useEffect(()=>{
    window.scrollTo(50,50)
  })
  return (
    <>
      
      <Fade bottom>
      <div className="digital-marketing-page-banner">
        <h1 className='digital-marketing-banner-text'>Best Digital Marketing Service in Himachal Pradesh</h1>
      </div>
      </Fade>

<Fade bottom>
      <section className="digital-marketing-about-section">
  <h2>About Our Digital Marketing Services</h2>
  <p>BrightNext Technology is a digital marketing agency based in Himachal Pradesh, specializing in helping businesses of all sizes grow their online presence and reach their target audience through various digital marketing channels. We understand the importance of having a strong online presence in today's competitive digital landscape.</p>
  <p>Our team of experienced digital marketers work closely with our clients to understand their business goals and develop a customized digital marketing strategy to meet those goals. We use the latest digital marketing techniques and tools to ensure that our clients' online marketing efforts are effective, efficient, and optimized for maximum return on investment.</p>
  <p>Whether you need help with search engine optimization, social media marketing, pay-per-click advertising, email marketing, or any other aspect of digital marketing, we have the expertise to deliver results. Contact us today to learn more about our digital marketing services and how we can help grow your business online.</p>
</section>
</Fade>


<Fade bottom>
<section className="digital-marketing-related-section">
  <h2>Digital Marketing Services in Mohali and Himachal Pradesh</h2>
  <p>As a leading digital marketing agency in Mohali and Himachal Pradesh, we understand the importance of creating marketing strategies that capture the essence of the state's natural beauty. Our team of experienced marketers specialize in creating engaging content that resonates with local audiences while also reaching a global audience.</p>
  <p>We offer our digital marketing services to businesses in Mohali, a rapidly growing business hub in Punjab, as well as throughout Himachal Pradesh. Our marketing strategies are designed to be modern and professional, ensuring that your business stands out in the crowded digital market.</p>
</section>
</Fade>

<Fade bottom >
<section className="digital-marketing-services-section">
        <h2>Our Digital Marketing Services</h2>
        <p>At BrightNext Technology, we specialize in providing comprehensive digital marketing solutions that can help businesses of all sizes establish a strong online presence and reach their target audience effectively. Our digital marketing services include:</p>
        <ul>
          <li>Search Engine Optimization (SEO)</li>
          <li>Social Media Marketing (SMM)</li>
          <li>Pay-per-Click Advertising (PPC)</li>
          <li>Email Marketing</li>
          <li>Content Marketing</li>
        </ul>
        <p>Our team of experienced digital marketers work closely with clients to develop customized strategies that meet their unique business needs. We use the latest tools and techniques to ensure that our digital marketing campaigns are effective, targeted, and measurable. Contact us today to learn more about our digital marketing services and how we can help you achieve your business goals.</p>
      </section>
</Fade>





      <section class="digital-marketing-process-section">
  <h2 class="process-title">Our Digital Marketing Process</h2>
  <p class="process-description">At our digital marketing service, we follow a well-defined process to ensure that each marketing project is completed to the highest standards:</p>
  <ul class="process-steps">
    <li class="process-step">
      <h3 class="process-step-title">Step 1: Discovery</h3>
      <p class="process-step-description">We start by understanding your business and marketing goals, including your target audience and desired outcomes.</p>
    </li>
    <li class="process-step">
      <h3 class="process-step-title">Step 2: Strategy</h3>
      <p class="process-step-description">Our team will create a customized digital marketing strategy based on your business goals and target audience, incorporating the latest trends and techniques.</p>
    </li>
    <li class="process-step">
      <h3 class="process-step-title">Step 3: Implementation</h3>
      <p class="process-step-description">Based on the approved strategy, we will begin implementing various digital marketing tactics, including search engine optimization, social media marketing, content marketing, and more.</p>
    </li>
    <li class="process-step">
      <h3 class="process-step-title">Step 4: Monitoring and Optimization</h3>
      <p class="process-step-description">We will continually monitor and optimize your digital marketing campaigns to ensure they are delivering the desired results and meeting your business objectives.</p>
    </li>
  </ul>
  <p class="process-end">Our digital marketing process ensures that you receive a comprehensive and effective digital marketing campaign that helps your business grow and succeed online.</p>
</section>


<ContactBanner/>

<Fade bottom>
<section class="digital-marketing-portfolio-section">
  <h2>Our Digital Marketing Portfolio</h2>
  <p>Here are some examples of our recent digital marketing projects:</p>
  <div class="portfolio-items-container">
    <div class="portfolio-item">
      <img src="project-1-image.webp" alt="Project 1" />
      <h3>Project 1</h3>
      <p>A brief description of the project and the marketing solutions we provided.</p>
    </div>
    <div class="portfolio-item">
      <img src="project-2-image.webp" alt="Project 2" />
      <h3>Project 2</h3>
      <p>A brief description of the project and the marketing solutions we provided.</p>
    </div>
    <div class="portfolio-item">
      <img src="project-3-image.webp" alt="Project 3" />
      <h3>Project 3</h3>
      <p>A brief description of the project and the marketing solutions we provided.</p>
    </div>
  </div>
</section>
</Fade>

    </>
  )
}

export default DigitalMarketingPage;
