import React, { useEffect } from 'react';
import './css/uiux.css';
import Fade from 'react-reveal/Fade';
import ContactBanner from '../ContactBanner';

function UiUXPage() {
  useEffect(()=>{
    window.scrollTo(50,50)
  })
  return (
    <>
      <Fade bottom>
      <div className="uiux-page-banner">
        <h1 className='uiux-banner-text'>Best UI and UX Service in Himachal Pradesh</h1>
      </div>
</Fade>

<Fade bottom>
      <section className="uiux-about-section">
  <h2>About Our UI/UX Design Services</h2>
  <p>At BrightNext Technology, we provide custom UI/UX design solutions that are tailored to meet the specific needs and goals of our clients. We believe that a well-designed user interface and user experience are crucial to the success of any digital product or service.</p>
  <p>Our experienced team of UI/UX designers work closely with clients to understand their business goals and target audience, as well as the unique requirements of their project. We use the latest design tools and techniques to create intuitive and user-friendly interfaces that deliver a seamless user experience.</p>
  <p>Whether you need help with web design, mobile app design, or any other aspect of UI/UX design, we can help. Contact us today to learn more about our UI/UX design services and how we can help you create a digital product or service that is both visually appealing and user-friendly.</p>
</section>
</Fade>

<Fade bottom>
<section className="uiux-related-section">
  <h2>UI/UX Design Services in Mohali and Himachal Pradesh</h2>
  <p>At BrightNext Technology, we offer top-notch UI/UX design services to businesses in Mohali and Himachal Pradesh. Our team of experienced designers understand the importance of creating user-centered designs that not only look great but also provide a seamless user experience.</p>
  <p>Whether you are launching a new website or mobile app, we can help you create a user-friendly interface that reflects your brand and resonates with your target audience. Our design process involves extensive research and testing to ensure that the final product meets your business goals and user needs.</p>
  <p>Contact us today to learn more about our UI/UX design services and how we can help elevate your digital presence in Mohali and Himachal Pradesh.</p>
</section>
</Fade>


<Fade bottom>
<section className="uiux-services-section">
        <h2>Our UI/UX Design Services</h2>
        <p>At BrightNext Technology, we specialize in providing comprehensive UI/UX design solutions that can help businesses of all sizes create intuitive and visually appealing digital experiences for their users. Our UI/UX design services include:</p>
        <ul>
          <li>User Research and Analysis</li>
          <li>Information Architecture Design</li>
          <li>Wireframing and Prototyping</li>
          <li>Visual Design</li>
          <li>Usability Testing</li>
        </ul>
        <p>Our team of experienced UI/UX designers work closely with clients to understand their users' needs and create designs that are intuitive, accessible, and aesthetically pleasing. We use the latest design tools and techniques to ensure that our UI/UX solutions are effective, user-friendly, and optimized for maximum engagement. Contact us today to learn more about our UI/UX design services and how we can help you create a compelling digital experience for your users.</p>
      </section>
</Fade>





<section class="uiux-process-section">
  <h2 class="process-title">Our UI/UX Process</h2>
  <p class="process-description">At our UI/UX design service, we follow a well-defined process to ensure that each design project is user-centered and visually appealing:</p>
  <ul class="process-steps">
    <li class="process-step">
      <h3 class="process-step-title">Step 1: Research</h3>
      <p class="process-step-description">We start by researching your users' needs, behaviors, and expectations to better understand how they interact with your product or service.</p>
    </li>
    <li class="process-step">
      <h3 class="process-step-title">Step 2: Design</h3>
      <p class="process-step-description">Our team will create a customized UI/UX design based on your users' needs and your business goals, incorporating the latest design trends and best practices.</p>
    </li>
    <li class="process-step">
      <h3 class="process-step-title">Step 3: Development</h3>
      <p class="process-step-description">Based on the approved design, we will begin developing the user interface and experience, including prototyping, wireframing, and user testing.</p>
    </li>
    <li class="process-step">
      <h3 class="process-step-title">Step 4: Iteration and Improvement</h3>
      <p class="process-step-description">We will continually iterate and improve the UI/UX design and development to ensure that it meets your users' needs and expectations, and supports your business objectives.</p>
    </li>
  </ul>
  <p class="process-end">Our UI/UX process ensures that you receive a user-centered and visually appealing design that enhances your product or service and delights your users.</p>
</section>


<ContactBanner/>

<Fade bottom>
<section class="uiux-portfolio-section">
  <h2>Our UI/UX Portfolio</h2>
  <p>Here are some examples of our recent UI/UX design projects:</p>
  <div class="portfolio-items-container">
    <div class="portfolio-item">
      <img src="project-1-image.webp" alt="Project 1" />
      <h3>Project 1</h3>
      <p>A brief description of the project and our approach to UI/UX design to enhance user experience.</p>
    </div>
    <div class="portfolio-item">
      <img src="project-2-image.webp" alt="Project 2" />
      <h3>Project 2</h3>
      <p>A brief description of the project and our approach to UI/UX design to enhance user experience.</p>
    </div>
    <div class="portfolio-item">
      <img src="project-3-image.webp" alt="Project 3" />
      <h3>Project 3</h3>
      <p>A brief description of the project and our approach to UI/UX design to enhance user experience.</p>
    </div>
  </div>
</section>
</Fade>

    </>
  )
}

export default UiUXPage
