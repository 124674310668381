import React from 'react';
import './css/footer.css';
import MarqueeComponent from './MarqueeComponent';
import footerLogo from './images/footer-logo.webp';
import {useNavigate} from 'react-router-dom';

function Footer() {
  const navigate = useNavigate();

  let currentYear = new Date().getFullYear();
  return (
    <>
      <div className="footer-div-main">
    {/* First Div */}
        <div className="footer-divs footer-news">
        <img src={footerLogo} alt="FooterLogo" height='150px'/>
        <p className='footer-para'>Stay in the loop with our latest news and updates by subscribing to our newsletter.</p>

        <div className='footer-form'>
        <input type="email" placeholder='Your Email' /> 
        <button type="submit">Subscribe</button>
        </div>

        </div>

    {/* Second Div  */}
        <div className="footer-divs footer-get-in">
            <h3>Get In Touch</h3>
            <MarqueeComponent/>

              <span><i className='fa-solid fa-location-dot'></i>Mohali || Hamirpur</span>
              
              <span><i className='fa-solid fa-envelope'></i>thebrightnexttechnology@gmail.com</span>

              <span><i class="fa-solid fa-phone-volume"></i>+91 94599 07845</span>

              <div className="footer-social-links">
              <span><a href='https://twitter.com/BrightnextT?s=20'><i class="fa-brands fa-twitter" title='Twitter'></i></a></span>
                <span><a href='https://www.instagram.com/brightnext.technology/'><i class="fa-brands fa-instagram" title='Instagarm'></i></a></span>
                <span><i class="fa-brands fa-linkedin" title='LinkedIn'></i></span>
                <span><i class="fa-brands fa-facebook" title='Facebook'></i></span>
              </div>

        </div>

    {/* Third Div */}

    <div className="footer-divs footer-links-div">
            <h3>Quick Links</h3>
            <MarqueeComponent/>
            <ul className='footer-links'>
                <li onClick={()=>navigate('/')}>&rarr; Home</li>
                <li onClick={()=>navigate('/about')}>&rarr; About Us</li>
                <li onClick={()=>navigate('/pricing')}>&rarr; Pricing</li>
                <li onClick={()=>navigate('/contact')}>&rarr; Contact Us</li>
            </ul>
        </div>

    

        {/* Forth Div */}
        

        <div className="footer-divs footer-links-div">
        <h3>Our Services</h3>
        <MarqueeComponent/>
        <ul className='footer-links'>
          <li onClick={()=>navigate('/services/graphics')}>&rarr; Graphic Designing</li>
          <li onClick={()=>navigate('/services/webdev')}>&rarr; Web Development</li>
          <li onClick={()=>navigate('/services/appdev')}>&rarr; App Development</li>
          <li onClick={()=>navigate('/services/digitalmarketing')}>&rarr; Digital Marketing</li>
          <li onClick={()=>navigate('/services/uiux')}>&rarr; UI and UX Designing</li>
        </ul>
      </div>
        

      </div>

      

      {/* Footer banner Div */}
      <div className="footer-banner">
            <p className='banner-para'> Copyright &copy; {currentYear}  BrightNext Technology. All rights reserved. Designed by 
            <a target='_blank' href='https://github.com/Gaurav-WebDev'> Gaurav Sharma</a></p>
        </div>
    </>
  )
}

export default Footer;
