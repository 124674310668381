import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import AboutUsPage from './component/AboutUsPage';
import ContactUsPage from './component/ContactUsPage';
import Footer from './component/Footer';
import Home from './component/Home';
import Navbanner from './component/Navbar';
import Navigation from './component/Navbar2';
import PricingPage from './component/PricingPage';
import ImageLoader from './component/ImageLoader';
import PageNotFound from './component/PageNotFound';
import GraphicDesigningPage from './component/servicespages/GraphicDesigningPage';
import WebDevelopmentPage from './component/servicespages/WebDevelopmentPage';
import AppDevelopmentPage from './component/servicespages/AppDevelopmentPage';
import DigitalMarketingPage from './component/servicespages/DigitalMarketingPage';
import UiUXPage from './component/servicespages/UiUXPage';


function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <div>
      {loading ? ( // display the image loader if loading is true
        <ImageLoader />
      ) : ( // display the actual content if loading is false
        <>
          <Navbanner />
          <Navigation />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<AboutUsPage />} />
              <Route path="/services/graphics" element={<GraphicDesigningPage/>} />
              <Route path="/services/webdev" element={<WebDevelopmentPage/>} />
              <Route path="/services/appdev" element={<AppDevelopmentPage/>} />
              <Route path="/services/digitalmarketing" element={<DigitalMarketingPage/>} />
              <Route path="/services/uiux" element={<UiUXPage/>} />
            <Route path="/pricing" element={<PricingPage />} />
            <Route path="/contact" element={<ContactUsPage />} />
            <Route path='*' element={<PageNotFound />}></Route>
          </Routes>

          <Footer />
        </>
      )}
    </div>
  );
}

export default App;
