import React, { useEffect } from 'react';
import './css/webdev.css';
import Fade from 'react-reveal/Fade';
import ContactBanner from '../ContactBanner';

function WebDevelopmentPage() {
  useEffect(()=>{
    window.scrollTo(50,50)
  })
  return (
    <>
    <Fade bottom>
         <div className="webdev-page-banner">
        <h1 className='webdev-banner-text'>Best Website Development Service in Himachal Pradesh</h1>
      </div>
</Fade>

<Fade bottom>
      <section className="webdev-about-section">
  <h2>About Our Web Development Services</h2>
  <p>BrightNext Technology is a web development company based in Himachal Pradesh, specializing in creating high-quality and responsive websites for businesses of all sizes. We understand that a website is often the first point of contact between a business and its customers, and our goal is to create a website that not only looks great, but also delivers a seamless user experience.</p>
  <p>Our team of experienced developers and designers work closely with our clients to understand their unique needs and develop a customized solution that meets those needs. We use the latest web technologies and design trends to ensure that our websites are modern, functional, and optimized for performance and search engines.</p>
  <p>Whether you need a simple brochure website or a complex web application, we have the expertise to deliver a high-quality solution. Contact us today to learn more about our web development services and how we can help take your business to the next level.</p>
</section>
</Fade>

<Fade bottom>
<section className="webdev-related-section">
        <h2>Web Development in Mohali and Himachal Pradesh</h2>
        <p>As one of the leading web development services in Himachal Pradesh, we specialize in creating websites that showcase the unique essence of the state's natural beauty. From the snow-capped mountains to the lush green valleys, we incorporate the beauty of Himachal Pradesh into our websites to create a truly unique online experience.</p>
        <p>Additionally, we also offer our services in Mohali, which is a rapidly growing business hub in Punjab. Our websites for Mohali-based businesses are created with a modern and professional aesthetic, ensuring that they stand out from the competition.</p>
      </section>
</Fade>


<Fade bottom>
      <section className="webdev-services-section">
        <h2>Our Web Development Services</h2>
        <p>We offer a wide range of web development services to help businesses of all sizes achieve their online goals. Our services include:</p>
        <ul>
          <li>Custom Website Development</li>
          <li>Website Redesign and Optimization</li>
          <li>E-commerce Website Development</li>
          <li>Content Management System (CMS) Development</li>
          <li>Web Application Development</li>
        </ul>
        <p>Contact us today to discuss your specific web development needs and how we can help you establish a strong online presence.</p>
      </section>
</Fade>




      <section class="webdev-process-section">
  <h2 class="process-title">Our Web Development Process</h2>
  <p class="process-description">At our web development service, we follow a well-defined process to ensure that each website project is completed to the highest standards:</p>
  <ul class="process-steps">
    <li class="process-step">
      <h3 class="process-step-title">Step 1: Discovery</h3>
      <p class="process-step-description">We start by understanding your business and website goals, including your target audience and desired functionality.</p>
    </li>
    <li class="process-step">
      <h3 class="process-step-title">Step 2: Design</h3>
      <p class="process-step-description">Our team will create multiple design concepts for your review and feedback, including wireframes and prototypes.</p>
    </li>
    <li class="process-step">
      <h3 class="process-step-title">Step 3: Development</h3>
      <p class="process-step-description">Based on your approved design, we will begin developing your website, ensuring it is responsive and optimized for search engines.</p>
    </li>
    <li class="process-step">
      <h3 class="process-step-title">Step 4: Testing and Launch</h3>
      <p class="process-step-description">We will thoroughly test your website for functionality, usability, and performance, and launch it once it meets our high standards.</p>
    </li>
  </ul>
  <p class="process-end">Our web development process ensures that you receive a high-quality website that meets your specific needs and requirements.</p>
</section>

<ContactBanner/>

<Fade bottom>
<section class="webdev-portfolio-section">
  <h2>Our Web Development Portfolio</h2>
  <p>Here are some examples of our recent web development projects:</p>
  <div class="portfolio-items-container">
    <div class="portfolio-item">
      <img src="project-1-image.webp" alt="Project 1" />
      <h3>Project 1</h3>
      <p>A brief description of the project and the development solutions we provided.</p>
    </div>
    <div class="portfolio-item">
      <img src="project-2-image.webp" alt="Project 2" />
      <h3>Project 2</h3>
      <p>A brief description of the project and the development solutions we provided.</p>
    </div>
    <div class="portfolio-item">
      <img src="project-3-image.webp" alt="Project 3" />
      <h3>Project 3</h3>
      <p>A brief description of the project and the development solutions we provided.</p>
    </div>
  </div>
</section>
</Fade>
    </>
  )
}

export default WebDevelopmentPage
