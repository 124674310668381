import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Navbar, Nav, Container, Button, NavDropdown } from 'react-bootstrap';
import logo from './images/logo.webp';
import './css/navbar2.css';


const Navigation = () => {
    const [navfixed, setNavfixed] = useState("")

    useEffect(()=>{
        const handelScroll =()=>{
           const offsetTop = window.pageYOffset ;
           setNavfixed(offsetTop > 0)
        };

        window.addEventListener("scroll", handelScroll);

        return ()=> window.removeEventListener("scroll", handelScroll);
    }, []);


  const [expanded, setExpanded] = useState(false);

  const closeNav = () => {
    setExpanded(false);
  };

  const navigate = useNavigate();

  const contactBtn = ()=>{
    navigate('/contact');
    closeNav();
  }

  return (
    <Navbar bg="light" expand="lg" expanded={expanded} className= {navfixed ? 'fixed' : " "}>
      <Container>
        <Navbar.Brand href="/">
          <img
            src={logo}
            height="60"
            className="d-inline-block align-top"
            alt="Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="navbar-nav"
          onClick={() => setExpanded(expanded ? false : true)}
        >
          {expanded ? (
            <i className="fas fa-times"></i>
          ) : (
            <i className="fas fa-bars"></i>
          )}
        </Navbar.Toggle>
        <Navbar.Collapse id="navbar-nav">
          <Nav className="me-auto my-nav " >
            <Nav.Link className='my-nav-link' as={NavLink} exact to="/" activeClassName="active" onClick={closeNav}>
              Home
            </Nav.Link>
            <Nav.Link className='my-nav-link' as={NavLink} to="/about" activeClassName="active" onClick={closeNav}>
              About
            </Nav.Link>
            <NavDropdown title="Services" id="basic-nav-dropdown" className="nav-dropdown-container">
  <NavDropdown.Item className="nav-dropdown" as={NavLink} to="/services/graphics" activeClassName="active" onClick={closeNav}>
    Graphic Designing
  </NavDropdown.Item>
  <NavDropdown.Item className="nav-dropdown" as={NavLink} to="/services/webdev" activeClassName="active" onClick={closeNav}>
    Web Development
  </NavDropdown.Item>
  <NavDropdown.Item className="nav-dropdown" as={NavLink} to="/services/appdev" activeClassName="active" onClick={closeNav}>
    App Development
  </NavDropdown.Item>
  <NavDropdown.Item className="nav-dropdown" as={NavLink} to="/services/digitalmarketing" activeClassName="active" onClick={closeNav}>
    Digital Marketing
  </NavDropdown.Item>
  <NavDropdown.Item className="nav-dropdown" as={NavLink} to="/services/uiux" activeClassName="active" onClick={closeNav}>
    UI and UX Designing
  </NavDropdown.Item>
</NavDropdown>

            <Nav.Link className='my-nav-link' as={NavLink} to="/pricing" activeClassName="active" onClick={closeNav}>
              Pricing
            </Nav.Link>
            <Nav.Link className='my-nav-link' as={NavLink} to="/contact" activeClassName="active" onClick={closeNav}>
              Contact
            </Nav.Link>
            <Button variant="primary" className="mr-2 my-nav-link my-nav-btn" onClick={contactBtn}>
            Get in Touch
          </Button>
          </Nav>
         
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;
