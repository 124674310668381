import React, { useEffect } from 'react';
import './css/appdev.css';
import Fade from 'react-reveal/Fade';
import ContactBanner from '../ContactBanner';

function AppDevelopmentPage() {
  useEffect(()=>{
    window.scrollTo(50,50)
  })
  return (
    <>
      
      <Fade bottom>
      <div className="appdev-page-banner">
        <h1 className='appdev-banner-text'>Best App Development Service in Himachal Pradesh</h1>
      </div>
      </Fade>


<Fade bottom>
      <section className="appdev-about-section">
  <h2>About Our App Development Services</h2>
  <p>BrightNext Technology is an app development company based in Himachal Pradesh, specializing in creating high-quality and user-friendly mobile applications for businesses of all sizes. We understand the importance of having a well-designed and functional mobile app to reach and engage with your target audience.</p>
  <p>Our team of experienced app developers work closely with our clients to understand their unique needs and develop a customized solution that meets those needs. We use the latest mobile app development technologies and design trends to ensure that our apps are modern, functional, and optimized for performance.</p>
  <p>Whether you need a simple app or a complex enterprise-level solution, we have the expertise to deliver a high-quality and user-friendly application. Contact us today to learn more about our app development services and how we can help take your business to the next level.</p>
</section>
</Fade>

<Fade bottom>
<section className="appdev-related-section">
  <h2>App Development in Mohali and Himachal Pradesh</h2>
  <p>As one of the leading app development services in Himachal Pradesh, we specialize in creating mobile applications that capture the essence of the state's natural beauty. We understand the importance of user experience, and we incorporate the beauty of Himachal Pradesh into our app designs to create a unique and engaging experience for users.</p>
  <p>We also offer our services in Mohali, a rapidly growing business hub in Punjab. Our apps for Mohali-based businesses are created with a modern and professional aesthetic, ensuring that they stand out in the crowded app market.</p>
</section>
</Fade>

<Fade bottom>
<section className="appdev-services-section">
        <h2>Our App Development Services</h2>
        <p>At BrightNext Technology, we specialize in developing custom mobile applications that are tailored to meet the unique needs of your business. Our app development services include:</p>
        <ul>
          <li>iOS App Development</li>
          <li>Android App Development</li>
          <li>Cross-platform App Development</li>
          <li>Mobile App UI/UX Design</li>
          <li>App Maintenance and Support</li>
        </ul>
        <p>Our experienced team of developers and designers work closely with clients to understand their business goals and develop customized app solutions that exceed their expectations. Contact us today to learn more about our app development services and how we can help you build a powerful mobile presence for your business.</p>
      </section>
</Fade>




      <section class="appdev-process-section">
  <h2 class="process-title">Our App Development Process</h2>
  <p class="process-description">At our app development service, we follow a well-defined process to ensure that each mobile app project is completed to the highest standards:</p>
  <ul class="process-steps">
    <li class="process-step">
      <h3 class="process-step-title">Step 1: Discovery</h3>
      <p class="process-step-description">We start by understanding your business and app goals, including your target audience and desired functionality.</p>
    </li>
    <li class="process-step">
      <h3 class="process-step-title">Step 2: Design</h3>
      <p class="process-step-description">Our team will create multiple design concepts for your review and feedback, including wireframes and prototypes.</p>
    </li>
    <li class="process-step">
      <h3 class="process-step-title">Step 3: Development</h3>
      <p class="process-step-description">Based on your approved design, we will begin developing your mobile app, ensuring it is user-friendly and optimized for performance.</p>
    </li>
    <li class="process-step">
      <h3 class="process-step-title">Step 4: Testing and Launch</h3>
      <p class="process-step-description">We will thoroughly test your app for functionality, usability, and performance, and launch it once it meets our high standards.</p>
    </li>
  </ul>
  <p class="process-end">Our app development process ensures that you receive a high-quality mobile app that meets your specific needs and requirements.</p>
</section>


<ContactBanner/>

<Fade bottom>
<section class="appdev-portfolio-section">
  <h2>Our App Development Portfolio</h2>
  <p>Here are some examples of our recent app development projects:</p>
  <div class="portfolio-items-container">
    <div class="portfolio-item">
      <img src="project-1-image.webp" alt="Project 1" />
      <h3>Project 1</h3>
      <p>A brief description of the project and the development solutions we provided.</p>
    </div>
    <div class="portfolio-item">
      <img src="project-2-image.webp" alt="Project 2" />
      <h3>Project 2</h3>
      <p>A brief description of the project and the development solutions we provided.</p>
    </div>
    <div class="portfolio-item">
      <img src="project-3-image.webp" alt="Project 3" />
      <h3>Project 3</h3>
      <p>A brief description of the project and the development solutions we provided.</p>
    </div>
  </div>
</section>
</Fade>


    </>
  )
}

export default AppDevelopmentPage;
