import React, { useEffect } from 'react'
import Pricing from './Pricing'

function PricingPage() {
    useEffect(()=>{
        window.scrollTo(50,50);
       }, [])
  return (
    <>
      <Pricing/>
    </>
  )
}

export default PricingPage
