import React, { useEffect } from 'react';
import './css/graphicpage.css';
import Fade from 'react-reveal/Fade';
import photo1 from '../images/slide1.webp'
import photo2 from '../images/slide2.webp'
import photo3 from '../images/slide3.webp'
import ContactBanner from '../ContactBanner';

function GraphicDesigningPage() {
  useEffect(()=>{
    window.scrollTo(50,50)
  })
  return (
    <>
    <Fade bottom>
      <div className="graphic-page-banner">
        <h1 className='graphic-banner-text'>Best Graphic Designing Service in Himachal Pradesh</h1>
      </div>
      </Fade>

        
<Fade bottom>
      <section className="graphic-about-section">
        <h2>About Our Graphic Designing Service</h2>
        <p>We are a team of professional graphic designers based in Himachal Pradesh, specializing in creating stunning and effective designs for all types of businesses. Our design solutions are tailored to meet the unique needs of our clients, and we work closely with them to ensure that the final product exceeds their expectations.</p>
        <p>Whether you need a new logo, a brochure, or a website design, we have the skills and expertise to deliver high-quality results. Our team stays up-to-date with the latest design trends and technologies, ensuring that our clients always receive modern and effective designs.</p>
        <p>Contact us today to learn more about our graphic designing services and how we can help take your business to the next level.</p>
      </section>
</Fade>

<Fade bottom>
      <section className="graphic-related-section">
        <h2>Graphic Designing in Mohali and Himachal Pradesh</h2>
        <p>As one of the leading graphic designing services in Himachal Pradesh, we specialize in creating designs that capture the unique essence of the state's natural beauty. From the snow-capped mountains to the lush green valleys, we incorporate the beauty of Himachal Pradesh into our designs to create a truly unique look and feel.</p>
        <p>Additionally, we also offer our services in Mohali, which is a rapidly growing business hub in Punjab. Our designs for Mohali-based businesses are created with a modern and professional aesthetic, ensuring that they stand out from the competition.</p>
      </section>
</Fade>

<Fade bottom>
      <section className="graphic-services-section">
        <h2>Our Graphic Designing Services</h2>
        <p>We offer a wide range of graphic designing services to help businesses of all sizes achieve their goals. Our services include:</p>
        <ul>
          <li>Logo Design</li>
          <li>Brochure Design</li>
          <li>Website Design</li>
          <li>Social Media Graphics</li>
          <li>Business Card Design</li>
        </ul>
        <p>Contact us today to discuss your specific design needs and how we can help.</p>
      </section>
</Fade>



<section class="graphic-process-section">
  <h2 class="process-title">Our Design Process</h2>
  <p class="process-description">At our graphic designing service, we follow a well-defined process to ensure that each design project is completed to the highest standards:</p>
  <ul class="process-steps">
    <li class="process-step">
      <h3 class="process-step-title">Step 1: Discovery</h3>
      <p class="process-step-description">We start by understanding your business and design goals.</p>
    </li>
    <li class="process-step">
      <h3 class="process-step-title">Step 2: Design</h3>
      <p class="process-step-description">Our team will create multiple design concepts for your review and feedback.</p>
    </li>
    <li class="process-step">
      <h3 class="process-step-title">Step 3: Revision</h3>
      <p class="process-step-description">Based on your feedback, we will make revisions to the chosen design until it meets your expectations.</p>
    </li>
    <li class="process-step">
      <h3 class="process-step-title">Step 4: Delivery</h3>
      <p class="process-step-description">Once the design is approved, we will deliver the final files in the appropriate formats.</p>
    </li>
  </ul>
  <p class="process-end">Our design process ensures that you receive high-quality designs that meet your specific needs and requirements.</p>
</section>

<ContactBanner/>

<Fade bottom>
      <section className="graphic-portfolio-section">
  <h2>Our Graphic Designing Portfolio</h2>
  <p>Take a look at some of our recent graphic designing projects:</p>

  <div className="portfolio-items-container">
    <div className="portfolio-item">
      <img src={photo1} alt="Project 1" />
      <h3>Project 1</h3>
      <p>A brief description of the project and the design solutions we provided.</p>
    </div>
    <div className="portfolio-item">
      <img src={photo2} alt="Project 2" />
      <h3>Project 2</h3>
      <p>A brief description of the project and the design solutions we provided.</p>
    </div>
    <div className="portfolio-item">
      <img src={photo3} alt="Project 3" />
      <h3>Project 3</h3>
      <p>A brief description of the project and the design solutions we provided.</p>
    </div>
  </div>
</section>
</Fade>

</>
) }

export default GraphicDesigningPage;
