import React from 'react'
import './css/pricing.css'
import MarqueeComponent from './MarqueeComponent';
import Fade from 'react-reveal/Fade'
 
function Pricing() {
  return (
    <>
<Fade bottom>
<div className='pricing-plan-div'>
        <h3>PRICING PLANS</h3>
        <h2>We are Offering Competitive</h2>
        <h2>Prices for Our Clients</h2>
        <MarqueeComponent/>
    </div> 
</Fade>
{/* Pricing Plans Cards */}
     <div className='pricing-plans-cards'>
{/* Basic Plan Card */}
<Fade bottom>
        <div className='pricing-card price-1'>

            <div className='card-heading'>
                <h2>Basic Plan</h2>
            </div>

            <div className='card-head-para '>
                <p>FOR SMALL SIZE BUSINESS</p>
            </div>

            <hr />

            {/* <div className='card-price'>
                <h2><sup>$</sup>49.00<sub>/Month</sub></h2>
            </div> */}

            <div className='card-features'>

                <div className="faeture-list">
                <li>HTML5 & CSS3</li>
                <i class="fa-solid fa-circle-check"></i>
                </div>   
                 
                <div className="faeture-list">
                <li>Bootstarp V5</li>
                <i class="fa-solid fa-circle-check"></i>
                </div> 

                <div className="faeture-list">
                <li>Responsive Layout</li>
                <i class="fa-solid fa-circle-xmark"></i>
                </div>

                <div className="faeture-list">
                <li>Cross-Browser Support</li>
                <i class="fa-solid fa-circle-xmark"></i>
                </div>

            </div>

            <div className='card-order-btn'>
                <button className='order-btn'>Order Now</button>
            </div>

        </div>
 </Fade>
{/* Standard Plan */}
<Fade bottom>
        <div className='pricing-card price-2'>

        <div className='card-heading'>
                <h2>Standard Plan</h2>
            </div>

            <div className='card-head-para'>
                <p>FOR MEDIUM SIZE BUSINESS</p>
            </div>

            <hr />

            {/* <div className='card-price'>
                <h2><sup>$</sup>99.00<sub>/Month</sub></h2>
            </div> */}

            <div className='card-features'>
                <div className="faeture-list">
                <li>HTML5 & CSS3</li>
                <i class="fa-solid fa-circle-check"></i>
                </div>   
                 
                <div className="faeture-list">
                <li>Bootstarp V5</li>
                <i class="fa-solid fa-circle-check"></i>
                </div> 

                <div className="faeture-list">
                <li>Responsive Layout</li>
                <i class="fa-solid fa-circle-check"></i>
                </div>

                <div className="faeture-list">
                <li>Cross-Browser Support</li>
                <i class="fa-solid fa-circle-xmark"></i>
                </div>
            </div>


            <div className='card-order-btn'>
                <button className='order-btn'>Order Now</button>
            </div>

        </div>
 </Fade>
{/* Advanced Plan*/}
<Fade bottom>
        <div className='pricing-card price-3'>

        <div className='card-heading'>
                <h2>Advanced Plan</h2>
            </div>

            <div className='card-head-para'>
                <p>FOR LARGE SIZE BUSINESS</p>
            </div>

            <hr />

            {/* <div className='card-price'>
                <h2><sup>$</sup> 149.00 <sub>/Month</sub></h2>
            </div> */}

            <div className='card-features'>
            <div className="faeture-list">
                <li>HTML5 & CSS3</li>
                <i class="fa-solid fa-circle-check"></i>
                </div>   
                 
                <div className="faeture-list">
                <li>Bootstarp V5</li>
                <i class="fa-solid fa-circle-check"></i>
                </div> 

                <div className="faeture-list">
                <li>Responsive Layout</li>
                <i class="fa-solid fa-circle-check"></i>
                </div>

                <div className="faeture-list">
                <li>Cross-Browser Support</li>
                <i class="fa-solid fa-circle-check"></i>
                </div>
            </div>


            <div className='card-order-btn'>
                <button className='order-btn'>Order Now</button>
            </div>

        </div>
</Fade>

     </div>
    </>
  )
}

export default Pricing;
