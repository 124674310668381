import React, { useEffect } from 'react'
import Quote from './Quote'

function ContactUsPage() {
    useEffect(()=>{
        window.scrollTo(50,50);
       }, [])
  return (
    <>
      <Quote/>
    </>
  )
}

export default ContactUsPage
