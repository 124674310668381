import React from 'react';
import { Carousel, CarouselItem } from 'react-bootstrap';
import slide1 from './images/slide1.webp';
import slide2 from './images/slide2.webp';
import slide3 from './images/slide3.webp';
import {useNavigate} from 'react-router-dom';
import './css/imageslider.css';

const slides = [
  {
    image: slide1, 
    caption: "We'll guide and support you to turn great ideas into reality",
    para: 'Web Designing, Web Development, App Development, Graphic Designing',
    buttonText: 'Contact Us',
  },
  {
    image: slide2,
    caption: "We take a unique and creative approach to observing and creating",
    para: 'Web Designing, Web Development, App Development, Graphic Designing',
    buttonText: 'Contact Us',
  },
  {
    image: slide3,
    caption: "Experienced team delivers extraordinary outcomes through expertise",
    para: 'Web Designing, Web Development, App Development, Graphic Designing',
    buttonText: 'Contact Us',
  },
];

const ImageSlider = () => {

  const navigate = useNavigate();
  return (
    <Carousel interval={7000} pause={false}>
      {slides.map((slide, index) => (
        <CarouselItem key={index}>
          <div className="slider-container">
            <div className="slider-image">
              <img className='img-fluid' src={slide.image} alt={slide.caption} />
            </div>
            <div className="slider-text">
              <h3>{slide.caption}</h3>
              <p>{slide.para}</p>
              <button onClick={()=>navigate('/contact')} className="btn btn-primary slider-btn">{slide.buttonText}</button>
            </div>
          </div>
        </CarouselItem>
      ))}
    </Carousel>
  );
};

export default ImageSlider;
