import React from 'react';
import { Fade } from 'react-reveal';
import { useNavigate } from 'react-router-dom';
import './css/about.css';
import aboutImg from './images/about.jpg'
import MarqueeComponent from './MarqueeComponent';
// import { Fade } from 'react-reveal';

function About() {
  const navigate = useNavigate();
  return (
   <>
   
   <div className='main-about-div'>
    <div className='about-div'>


    <div className='about-text'>
      <Fade bottom>
    <h4>About Us</h4>
    <h1>The Best IT Solution With 6 </h1>
    <h1>Years of Experience</h1>
    <MarqueeComponent/>
    <br />
    <br/>
    </Fade>
    </div>
    
    <div className='about-checks'>
    <Fade bottom>
      <div className='checks'>
        <span><i class="fa-solid fa-circle-check"></i>Award Winning</span>
        <br />
        <span><i class="fa-solid fa-circle-check"></i>Professional Staff</span>
      </div>
      <div className='checks'>
        <span><i class="fa-solid fa-circle-check"></i>24/7 Support</span>
        <br />
        <span><i class="fa-solid fa-circle-check"></i>Affordable Prices</span>
      </div>
      </Fade>
    </div>

<br/>

    <div className='about-cta'>
    <Fade bottom>
      <div className='about-call'>
    <i class="fa-solid fa-phone"></i>
    </div>
    <div className='about-call'>
    <span>Call to ask any question</span>
    <br />
    <span>+91 94599 07845</span>
    </div>
    </Fade>
    </div>

    <Fade bottom>
    <div className='about-btn-div'>
    <button onClick={()=>navigate('/contact')}>Get in Touch</button>
    </div>
    </Fade>

    </div>

    <Fade bottom>
    <div className='about-div'>
    <div className='about-image'>
      <img src={aboutImg} className='img-fluid' alt='aboutimage' />
    </div>
    </div>
    </Fade>
   </div>
   </>
  )
}

export default About
