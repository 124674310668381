import React from 'react';
import Fade from 'react-reveal/Fade';
import MarqueeComponent from './MarqueeComponent';
import Member1 from './images/member1.jpg'
import './css/team.css';

function Team() {
  return (
    <>
    <Fade bottom>
<div className='team-div' id='team'>
        <h3>TEAM MEMBERS</h3>
        <h2>Professional Stuffs Ready to</h2>
        <h2>Help Your Business</h2>
        <MarqueeComponent/>
    </div> 
 </Fade>

 {/* Team Members Card */}
     <div className='team-members-cards'>

        {/* Card 1 */}
        <Fade bottom>
        <div className='members-card'>

        
        <div className="member-social">
                  <span><i class="fa-brands fa-twitter m-twitter" title='Twitter'></i></span>
                  <span><i class="fa-brands fa-instagram m-insta" title='Instagarm'></i></span>
                  <span><i class="fa-brands fa-linkedin m-linked" title='LinkedIn'></i></span>
                  <span><i class="fa-brands fa-facebook m-facebook" title='Facebook'></i></span>
               </div>

            <div className='team-card-img'>
                
                <img src={Member1} alt='Member1' />
                
            </div>

            
            <div className='member-detail'>
                <h4>Jatin Sharma</h4>
                <h6>Graphic Designer</h6>
            </div>
            
        </div>
</Fade>
         {/* Card 2 */}
         <Fade bottom>
         <div className='members-card'>
         
         <div className="member-social">
                  <span><i class="fa-brands fa-twitter m-twitter" title='Twitter'></i></span>
                  <span><i class="fa-brands fa-instagram m-insta" title='Instagarm'></i></span>
                  <span><i class="fa-brands fa-linkedin m-linked" title='LinkedIn'></i></span>
                  <span><i class="fa-brands fa-facebook m-facebook" title='Facebook'></i></span>
               </div>


         <div className='team-card-img'>
                <img src={Member1} alt='Member2' />
            </div>

            <div className='member-detail'>
                <h4>Gaurav Sharma</h4>
                <h6>Web Developer</h6>
            </div>
           
         </div>
         </Fade>
          {/* Card 3 */}
          <Fade bottom>
        <div className='members-card'>
       
        <div className="member-social">
                  <span><i class="fa-brands fa-twitter m-twitter" title='Twitter'></i></span>
                  <span><i class="fa-brands fa-instagram m-insta" title='Instagarm'></i></span>
                  <span><i class="fa-brands fa-linkedin m-linked" title='LinkedIn'></i></span>
                  <span><i class="fa-brands fa-facebook m-facebook" title='Facebook'></i></span>
               </div>


        <div className='team-card-img'>
                <img src={Member1} alt='Member3' />
            </div>

            <div className='member-detail'>
                <h4>Anirudh Sethi</h4>
                <h6>SEO Expert</h6>
            </div>
            
        </div>
        </Fade>
     </div>
    </>
  )
}

export default Team;
