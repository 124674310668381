import React, { useState, useEffect } from 'react';
import './css/gotop.css';

function GotoTop() {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 800) {
        setShow(true);
      } else {
        setShow(false);
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {show && (
        <a href='#top' className='go-top-btn'><i class="fa-solid fa-arrow-up"></i></a>
      )}
    </>
  );
}

export default GotoTop;
