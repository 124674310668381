import React from 'react';
import './css/navbar.css';



function Navbanner() {
  return (
    <div id='top'>  
        <div className='nav-banner'>
            <div className='nav-address'>
                <span>
                    <i class="fa-solid fa-location-dot"></i> <span>Mohali || Hamirpur</span>
                </span>
                <span><i class="fa-solid fa-phone"></i>+91 94599 07845</span>
                <span><i class="fa-regular fa-envelope"></i> thebrightnexttechnology@gmail.com</span>
            </div>
            <div className='nav-icons'>

                <span><a href='https://twitter.com/BrightnextT?s=20'><i class="fa-brands fa-twitter" title='Twitter'></i></a></span>
                <span><a href='https://www.instagram.com/brightnext.technology/'><i class="fa-brands fa-instagram" title='Instagarm'></i></a></span>
                <span><i class="fa-brands fa-linkedin" title='LinkedIn'></i></span>
                <span><i class="fa-brands fa-facebook" title='Facebook'></i></span>
            </div>
        </div>
    </div>
  )
}

export default Navbanner;
