import React from 'react';
import './css/brandsmarquee.css';
import brand1 from './images/Brands/Brands/brand1.jpg';
import brand2 from './images/Brands/Brands/brand2.jpg';
import brand3 from './images/Brands/Brands/brand3.jpg';
import brand4 from './images/Brands/Brands/brand4.jpg';
import brand5 from './images/Brands/Brands/brand5.jpg';
import brand6 from './images/Brands/Brands/brand6.jpg';
import brand7 from './images/Brands/Brands/brand7.jpg';
import brand8 from './images/Brands/Brands/brand8.jpg';
import brand9 from './images/Brands/Brands/brand9.jpg';
import brand10 from './images/Brands/Brands/brand10.jpg';
import brand11 from './images/Brands/Brands/brand11.jpg';
import brand12 from './images/Brands/Brands/brand12.jpg';
import brand13 from './images/Brands/Brands/brand13.jpg';
import brand14 from './images/Brands/Brands/brand14.jpg';
import brand15 from './images/Brands/Brands/brand15.jpg';


function BrandsMarquee() {
  return (
    <>

    <div className='brand-text-div'>

    </div>

{/* Brands Marquee */}
    <div className='brands-outter-div'>
        <marquee behavior='scroll' scrollamount='10'>

        <img src={brand1} alt='Brand1' /> 
        <img src={brand2} alt='Brand2' /> 
        <img src={brand3} alt='Brand3' /> 
        <img src={brand4} alt='Brand4' /> 
        <img src={brand5} alt='Brand5' /> 
        <img src={brand6} alt='Brand6' /> 
        <img src={brand7} alt='Brand7' /> 
        <img src={brand8} alt='Brand8' /> 
        <img src={brand9} alt='Brand9' /> 
        <img src={brand10} alt='Brand10' /> 
        <img src={brand11} alt='Brand11' /> 
        <img src={brand12} alt='Brand12' /> 
        <img src={brand13} alt='Brand13' /> 
        <img src={brand14} alt='Brand14' /> 
        <img src={brand15} alt='Brand15' /> 
         
        </marquee>
    </div>
    </>
  )
}

export default BrandsMarquee
