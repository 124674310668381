import React, { useEffect, useState } from 'react';
import './css/whtsapbtn.css';


function WhatsappBtn() {
    const [show, setShow] = useState(false);

    useEffect(() => {
      const handleScroll = () => {
        if (window.scrollY > 200) {
          setShow(true);
        } else {
          setShow(false);
        }
      };
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

  return (
    <>

{show && (
    <a href='https://wa.me/+919459907845?text=Hello%20BrightNext%20Technology' 
    className='whtsap-btn'><i class="fa-brands fa-square-whatsapp whtsapp"></i></a>
      )}
    
    </>
  )
}

export default WhatsappBtn;

