import React, { useState } from 'react';
import './css/counter.css';
import CountUp from 'react-countup';
import Fade from 'react-reveal/Fade';
import VisibilitySensor from 'react-visibility-sensor';

function Counter() {

  const [isVisible, setIsVisible] = useState(false);

  const onVisibilityChange = (isVisible) =>{
    if(isVisible){
      setIsVisible(true)
    }
  }
  return (
    <>
      
      <div className='main-counter-div'>
      <Fade bottom>
        <div className='counter client-counter'>
        
        <i class="fa-solid fa-users"></i>
        <div className='count-numbers'>
            <span>Happy Clients</span>
        <br />
        <span className='numbers'>
        <VisibilitySensor onChange={onVisibilityChange}>
        
        <CountUp 
          start={isVisible ? 0 : null}
          end={1343}
          duration={3.5}
        />
        </VisibilitySensor>
        </span>
        </div>
        
        </div>
        </Fade>


        <Fade bottom>
        <div className='counter project-counter'>
        
        <i class="fa-solid fa-check"></i>
        <div className='count-numbers'>
            <span>Project Done</span>
        <br />
        <span className='numbers'>
        <VisibilitySensor onChange={onVisibilityChange}>
        <CountUp 
          start={isVisible ? 0 : null}
          end={963}
          duration={3.5}
        />
        </VisibilitySensor>
        </span>
        </div>
        
        </div>
        </Fade>


        <Fade bottom>
        <div className='counter award-counter'>
        
        <i class="fa-solid fa-award"></i>
        <div className='count-numbers'>
            <span>Win Awards</span>
        <br />
        <span className='numbers'>
        <VisibilitySensor onChange={onVisibilityChange}>
        <CountUp 
          start={isVisible ? 0 : null}
          end={91}
          duration={3.5}
        />
        </VisibilitySensor>
        </span>
        </div>
        
        </div>
        </Fade>


      </div>





    </>
  )
}

export default Counter;
