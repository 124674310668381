import React from 'react';
import loader from './images/LoaderBg.webm';
import './css/loader.css';

function ImageLoader() {
  return (
    <>
      <div className="image-loader-container">
        <video autoPlay muted loop>
          <source src={loader} />
        </video>
      </div>
    </>
  )
}

export default ImageLoader;
