import React from 'react'
import MarqueeComponent from './MarqueeComponent';
import './css/services.css';
import Fade from 'react-reveal/Fade'
import {useNavigate} from 'react-router-dom';
import graphicicon from './images/serviceicons/graphic.webp' ;
import webicon from './images/serviceicons/webdev.webp' ;
import appicon from './images/serviceicons/appdev.webp' ;
import dmicon from './images/serviceicons/digimarket.webp' ;
import uiuxicon from './images/serviceicons/uiux.webp' ;

function Services() {
    const naviagte = useNavigate();
  return (
    <>
<Fade bottom>
<div className='our-services-div'>
        <h3>OUR SERVICES</h3>
        <h2>Custom IT Solutions for Your</h2>
        <h2>Successful Business</h2>
        <MarqueeComponent/>
    </div> 
 </Fade>
{/* /Services cards */}
      <div className='services-cards'>

      <Fade bottom>
{/* Card 1 -Graphics- */}
        <div className='cards'>

            <div className='cards-logo'>
            {/* <i class="fa-solid fa-blog logo-icon"></i> */}
            <img src={graphicicon} alt="GraphicDesign" />
            </div>

            <div className='cards-head'>
                <h3>Graphic Designing</h3>
            </div>

            <div className='cards-para'>
                <p>Unlock your brand's potential with our professional Graphic Designing services.</p>
            </div>

            <button  className='btnNext' onClick={()=>naviagte('/services/graphics')}><i class="fa-solid fa-arrow-right"></i></button>

        </div>
</Fade>

{/* Card 2 -web dev- */}
<Fade bottom>
        <div className='cards'>

        <div className='cards-logo'>
        {/* <i class="fa-solid fa-code web-icon"></i> */}
        <img src={webicon} alt="WebDev" />

            </div>

            <div className='cards-head'>
                <h3>Web Development</h3>
            </div>

            <div className='cards-para'>
                <p>Make your website stand out with our skilled Web Development services.</p>
            </div>

            <button className='btnNext' onClick={()=>naviagte('/services/webdev')}><i class="fa-solid fa-arrow-right"></i></button>

        </div>

 </Fade>
 {/* Card 3 -app dev- */}
 <Fade bottom>
        <div className='cards'>

            
        <div className='cards-logo'>
        {/* <i class="fa-solid fa-magnifying-glass seo-icon"></i> */}
        <img src={appicon} alt="AppDevelopment" />
            </div>

            <div className='cards-head'>
                <h3>App Development</h3>
            </div>

            <div className='cards-para'>
                <p>Transform your app idea into reality with our expert App Development services.</p>
            </div>

            <button className='btnNext' onClick={()=>naviagte('/services/appdev')}><i class="fa-solid fa-arrow-right"></i></button>
            
        </div>
 </Fade>
 {/* Card 4 -Digital Marketing- */}
 <Fade bottom>
        <div className='cards'>

            <div className='cards-logo'>
            {/* <i class="fa-solid fa-blog logo-icon"></i> */}
            <img src={dmicon} alt="DigitalMarketing" />
            </div>

            <div className='cards-head'>
                <h3>Digital Marketing</h3>
            </div>

            <div className='cards-para'>
                <p>Grow your business online with our effective Digital Marketing services.</p>
            </div>

            <button className='btnNext' onClick={()=>naviagte('/services/digitalmarketing')}><i class="fa-solid fa-arrow-right"></i></button>

        </div>
        
  </Fade>
{/* Card 5 -UI and UX- */}
<Fade bottom>
        <div className='cards'>

        <div className='cards-logo'>
        {/* <i class="fa-solid fa-code web-icon"></i> */}
        <img src={uiuxicon} alt="UiUxIcon" />
            </div>

            <div className='cards-head'>
                <h3>UI & UX Designing</h3>
            </div>

            <div className='cards-para'>
                <p>Create engaging user experiences and intuitive interfaces with our UI/UX Design services.</p>
            </div>

            <button className='btnNext' onClick={()=>naviagte('/services/uiux')}><i class="fa-solid fa-arrow-right"></i></button>

        </div>
 </Fade>
 {/* Card 6 -CTA-*/}
 <Fade bottom>
        <div className='cards cta-card'>

            
        <div className='cards-logo'>
            <h3>Get in Touch</h3>
            </div>

            <div className='cards-head'>
            <p>Feel Free to Contact US</p>
            </div>

            <div className='cards-para'>
            <h3>+91 94599 07845</h3>
            </div>
        
        </div>
        </Fade>


      </div> 
    </>
  )
}

export default Services;
