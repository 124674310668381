import React from 'react';
import './css/whywe.css';
import Fade from 'react-reveal/Fade';


function WhyWe() {
  return (
    <>
    <Fade bottom>
    <div className="whywediv">
        <h1>Why Choose BrightNext Technology?</h1>
        <ul>
            <li>Experties and Experience: Our team of experinced professionals has a proven track record of sucess in the marketing industury.</li>

            <li>Quality Assurance: We are dedicated to delivering the highest quality services to our customers. </li>

            <li>Customer Satisfaction: Our top priority is ensuring that our clients are satisfied with the service we provide to them.</li>

            <li>Cutting-Edge Technology: We use the latest tools and technologies to ensure that we are always ahead of the curve.</li>

            <li>Affordable Pricing: We believe that high-quality marketing services should't come at a premium price.</li>
        </ul>
    </div>
    </Fade>
    </>
  )
}

export default WhyWe
