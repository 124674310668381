import React from 'react';
import './css/contactbanner.css';
import  Tada from 'react-reveal/Tada';
import {useNavigate } from 'react-router-dom';

function ContactBanner() {
  const navigate = useNavigate();
  return (
    <>
    <Tada bottom cascade>
          <div class="contact-banner-div">
              <h2 class="banner-heading">Get in touch with us</h2>
              <p class="banner-subheading">Let's discuss your project needs</p>
              <button onClick={()=>navigate('/contact')} class="banner-cta">Contact Us</button>
          </div>
</Tada>

    </>
  )
}

export default ContactBanner
