import React from 'react';
import './css/marquee.css'

function MarqueeComponent() {
  return (
    <>
      <div className='marquee-outer'>
  <marquee behavior="alternate" scrollamount="10"><div className='marquee-inner'></div></marquee>
</div>
    </>
  )
}

export default MarqueeComponent
