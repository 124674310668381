import React from 'react';
import './css/testimonial.css';
import Fade from 'react-reveal/Fade'
import MarqueeComponent from './MarqueeComponent';
import avatar1 from './images/avatar.jpg';
import avatar2 from './images/avatar2.jpg';
import avatar3 from './images/avatar3.jpg'


function Testimonial() {
  return (
    <>
    <Fade bottom>
<div className='testimonial-div'>
        <h3>TESTIMONIAL</h3>
        <h2>What Our Clients Say About</h2>
        <h2>Our Digital Services</h2>
        <MarqueeComponent/>
    </div> 
 </Fade>

 {/* Testimonial Cards */}
       <div className='testimonial-cards-div'>
    
{/* testimonial card 1 */}
<Fade bottom>
        <div className='testimonial-cards card-1'>
            
            <div className='card-img'>
                <div className='avatar-img'>
                    <img src={avatar1} alt='avatar'/>
                </div>
                
                <div className='avatar-detail'>
                    <h4>Smith</h4>
                    <h6>Company CEO</h6>
                </div>
            </div>

<hr/>

            <div className='card-para'>
                <p>Tempor erat elitr rebum at clita. Diam dolor diam ipsum et tempor sit. Aliqu diam amet diam et eos labore.</p>
            </div>

        </div>
        </Fade>
{/* testimonial card 2 */}      

        <div className='testimonial-cards card-2'>

        <div className='card-img'>
                
        <div className='avatar-img'>
                    <img src={avatar2} alt='avatar'/>
                </div>
                
                <div className='avatar-detail'>
                    <h4>Harry</h4>
                    <h6>SEO Expert</h6>
                </div>

                </div>
                <hr />
                <div className='card-para'>
                    <p>Tempor erat elitr rebum at clita. Diam dolor diam ipsum et tempor sit. Aliqu diam amet diam et eos labore.</p>
                </div>
    
        </div>

{/* testimonial card 3 */}
<Fade bottom>
        <div className='testimonial-cards card-3'>
        

        <div className='card-img'>
                
        <div className='avatar-img'>
                    <img src={avatar3} alt='avatar'/>
                </div>
                
                <div className='avatar-detail'>
                    <h4>Marry</h4>
                    <h6>Web Developer</h6>
                </div>
            
                </div>
                <hr />   
                <div className='card-para'>
                    <p>Tempor erat elitr rebum at clita. Diam dolor diam ipsum et tempor sit. Aliqu diam amet diam et eos labore.</p>
                </div>
    
        </div>
 </Fade>
       </div>
    </>
  )
}

export default Testimonial
